.custom_nav {
  padding: 15px;
  background-color: white;
}

li.nav-item.benefits {
  margin-left: 369px;
}
li.nav-item:last-child {
  margin-left: 235px !important;
}
.nav_logo img {
  height: 175px;
  margin-left: 100px;
  position: absolute;
  top: -48px;
}
.logo img {
  height: 70px;
  margin-left: 80px;
}
.quote {
  margin-right: 60px;
}

.quote span {
  font-size: 16px;
  font-family: sans-serif;
}
.nav-item a {
  font-size: 18px;
}
section#banner {
  padding: 200px 0 40px;
  background-color: #f8f8f8;
}
.banner_content {
  padding: 0 60px;
}
.top_heading {
  padding: 6px 10px 6px 6px;
  background-color: #eaffb9;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
  width: 47%;
  margin: auto;
  gap: 24px;
}
.top_heading span {
  margin: 0 8px 0 0px;
  font-size: 16px;
  background-color: #bdff1c !important;
  color: black !important;
  letter-spacing: -0.3px;
}
.heading h3 {
  font-size: 72px;
  font-weight: 500;
  letter-spacing: -3px;
  line-height: 105%;
  text-align: center;
  width: 65%;
  margin: auto;
}
.heading {
  margin-top: 20px;
}
.supporting_text {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 135%;
  color: rgb(71, 84, 102);
  width: 60%;
  margin: auto;
  padding: 18px;
}
/* start: Avatar */
.avatar-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 45px;
}
.avatar {
  position: relative;
  transition: 0.2s;
}
.avatar:not(:first-child) {
  margin-left: -1.5rem;
}
.avatar:hover {
  z-index: 1;
  transform: translateY(-0.5rem);
}
.avatar-name {
  position: absolute;
  bottom: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 0.875rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}
.avatar-name::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 0.5rem solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent;
}
.avatar:hover .avatar-name {
  opacity: 1;
  visibility: visible;
}
.avatar img {
  width: 55px;
  height: 50px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
/* end: Avatar */
.social_trust {
  display: flex;
  width: 50%;
  margin: auto;
}
.stars img {
  height: 50px;
}
/* contact form start */
.contact-form-wrapper {
  padding: 100px 0px 0 0px;
  margin: -44px 0px 0 44px;
}

.contact-form {
  padding: 40px 32px;
  background-color: #fdffff;
  border-radius: 24px;
  box-shadow: 5px 25px 25px #0000001a;
  width: 100%;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-input,
.form-text-area {
  background-color: rgb(235, 235, 235);
  height: 50px;
  padding-left: 16px;
  font-family: sans-serif;
}

.contact-form .form-text-area {
  background-color: #f0f4f5;
  height: auto;
  padding-left: 16px;
}

.contact-form .form-control::placeholder {
  color: #aeb4b9;
  font-weight: 500;
  opacity: 1;
}

.contact-form .form-control:-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .title {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -1px;
  font-family: sans-serif;
}

.contact-form .description {
  color: rgb(71, 84, 102);
  font-size: 15px;
  text-align: center;
  letter-spacing: -0.02px;
  line-height: 135%;
}
.form_btn {
  width: 100%;
}

.frm_bottom {
  font-size: 14px;
  font-family: cursive;
  color: rgb(156, 163, 189);
  padding-top: 10px;
}
/* contact form end */
/* Benefits start*/
section#Benefits {
  padding: 56px 0 160px;
}
.heading_and_supporting {
  max-width: 800px;
}
.Benefits_heading h3 {
  font-size: 52px;
  font-weight: 500;
  letter-spacing: -2px;
  line-height: 120%;
  color: rgb(0, 123, 255);
  margin-bottom: 20px;
}

.supporting p {
  font-size: 20px;
  letter-spacing: -0.03em;
  color: rgb(71, 84, 102);
  line-height: 135%;
}

.card {
  background: #ffffff;
  box-shadow: 1px 4px 20px -2px rgba(0, 0, 0, 0.1);
  padding: 45px 45px;
  background-color: #bdff1c;
  border-radius: 24px;
  height: 400px;
}

.header img {
  height: 65px;
  border-radius: 38px;
  margin-bottom: 64px;
}

.content p {
  font-size: 26px;
  font-weight: 600;
  font-family: sans-serif;
  line-height: 120%;
  letter-spacing: -1px;
  color: rgb(15, 23, 40);
}

.support_text p {
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  font-family: sans-serif;
}
/* Benefits end*/
/* testimonial start */
section#testimonial {
  padding: 80px 60px;
  background-color: #f5f5f5;
}

.testi_heading h2 {
  font-size: 56px;
  font-family: fangsong;
  letter-spacing: -2px;
  line-height: 100%;
  color: rgb(15, 23, 40);
}

.testi_heading h2 span {
  color: rgb(163, 166, 174);
  display: block;
}

.arrow {
  position: absolute;
  left: 1040px;
  top: 238%;
  transform: rotate(-43deg);
}

.arrow img {
  height: 150px;
}
.testimonial-cards {
  display: flex;
  gap: 20px;
  position: relative;
  left: -45%;
  transform: translateX(-50%); /* Center-align the cards */
  width: 150%;
}

.star img {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 50%;
  top: 10px;
  transform: translateX(-50%) rotate(-18deg);
}

.card_2 {
  background-color: rgb(223, 250, 183) !important;
}

.btn.btn-outline-primary.testi_btn {
  border-radius: 25px;
  letter-spacing: -0.03em;
  font-size: 18px;
}

.testi_text p {
  font-size: 32px;
  letter-spacing: -1px;
  font-weight: 500;
  line-height: 120%;
  color: rgb(5, 5, 5);
}

.testi_text span {
  display: block;
}

span.u_description {
  font-size: 17px;
  font-family: sans-serif;
  color: rgb(71, 84, 102);
}

span.u_name {
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 600;
  line-height: 28px;
  color: rgb(15, 23, 40);
}

.parent-wrapper {
  margin: 55px 0px;
  overflow: hidden;
}

.testi_body {
  display: flex;
  height: auto;
  gap: 40px;
}

.image {
  overflow: hidden;
  width: 290%;
  border-radius: 10px;
}
.testi-card {
  height: 100%;
  padding: 40px;
  background: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(20px);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.testi-card h3 {
  margin-top: 0;
}

section#metrics {
  padding: 80px 60px 120px;
}

.heading_metrics h5 {
  font-size: 44px;
  color: rgb(15, 23, 40);
  font-weight: 500;
  line-height: 120%;
  text-align: center;
  font-family: sans-serif;
}

p.matrics_para {
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 135%;
  text-align: center;
}

p.matrics_para span {
  font-size: 88px;
  display: block;
  letter-spacing: -3px;
  line-height: 130%;
  color: rgb(0, 123, 255);
  text-align: center;
}
.bdr {
  border-right: solid 1px;
  border-color: #b9bed1;
}
/* testimonial end */

/* blogs start */
section#blogs {
  padding: 80px 0;
}

.blog_heading h5 {
  font-size: 46px;
  color: rgb(15, 23, 40);
  text-align: center;
  letter-spacing: -1.5px;
  line-height: 110%;
}

.col-lg-4.blogs img {
  border-radius: 47px;
  margin-top: 10px;
}

.col-lg-4.blogs {
  display: flex;
  gap: 16px;
  padding: 24px 0 24px 32px;
}

.content span {
  font-size: 24px;
  display: block;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -1px;
  color: rgb(15, 23, 40);
  margin-bottom: 10px;
}

.content {
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 135%;
  color: rgba(0, 0, 0, 0.5);
}

.blog_content {
  padding: 0 32px;
}
/* blogs end */
/* Faq start */
section#faq {
  padding: 80px 40px 0;
}

.faq_heading h2 {
  font-size: 52px;
  letter-spacing: -2px;
  line-height: 110%;
  font-weight: 500;
  color: rgb(15, 23, 40);
  text-align: center;
}

.faq_heading h2 span {
  display: block;
  color: rgb(163, 166, 174);
}

.faq_content {
  width: 72%;
  margin: auto;
}

.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 24px 0 30px;
}
.accordion {
  padding: 60px;
  --bs-accordion-bg: rgb(245, 245, 245);
  --bs-accordion-border-radius: 33px;
}
.accordion .accordion-item button[aria-expanded="true"] {
  border-bottom: 1px solid #03b5d2;
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #7288a2;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: #03b5d2;
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: #03b5d2;
  border: 1px solid #03b5d2;
}

.accordion button .accordion-title {
  font-size: 18px;
  letter-spacing: -0.02em;
  font-weight: 500;
  line-height: 135%;
  color: var(--extracted-r6o4lv, rgb(15, 23, 40));
}

/* .accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
} */

.accordion button[aria-expanded="true"] {
  color: #03b5d2;
}
.accordion button[aria-expanded="true"] .icon::after {
  width: 0;
}
.accordion button[aria-expanded="true"] + .accordion-content {
  opacity: 1;
  max-height: 9em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
}

.accordion button .icon::before {
  content: "+";
  display: inline-block;
  transition: transform 0.3s;
  position: absolute;
  left: 720px;
}

.accordion button[aria-expanded="true"] .icon::before {
  content: "+";
  transform: rotate(45deg);
}

.faq_supporting p {
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgb(15, 23, 40);
}

a.faq_link {
  color: #757575;
}
/* Faq end */
/* CTA start */
section#CTA {
  padding: 80px 64px;
}

.container.CTA_content {
  height: 480px;
  /* object-fit: fill; */
  overflow: hidden;
  background-color: #bdff1c;
  border-radius: 24px;
}

.CTA_image {
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  margin-top: -135px;
}

.left_content {
  padding: 80px 64px;
  gap: 90px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.left_heading h2 {
  font-size: 52px;
  letter-spacing: -1px;
  line-height: 110%;
  font-family: sans-serif;
  font-weight: 500;
}
.CTA_btn {
  border-radius: 25px;
}
/* CTA end */

/* footer start */
.footer_logo .logo img {
  height: 120px;
  margin-top: -38px;
}
.footer_content p {
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 135%;
  text-align: center;
  color: rgb(71, 84, 102);
  font-weight: 500;
  margin: 15px 0 0 0;
}

.footer_container {
  padding: 20px;
}

.footer_row {
  text-align: center;
}
/* .footer_logo {
  display: flex;
  justify-content: flex-start;
}

.footer_button {
  display: flex;
  justify-content: flex-end;
} */
/* footer end */

/* responsive */
@media screen and (max-width: 992px) {
  .arrow {
    display: none;
  }
  li.nav-item.benefits {
    margin-left: 0;
  }
  li.nav-item:last-child {
    margin-left: 0 !important;
  }
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    text-align: center;
  }
  section#Benefits {
    padding: 30px 0 70px;
  }

  .card {
    padding: 31px 15px;
    height: 500px;
  }

  .header img {
    height: 40px;
    margin-bottom: 40px;
  }

  .accordion button .icon::before {
    position: sticky;
  }
  .accordion {
    padding: 35px 0;
  }
  .left_content {
    padding: 15px 20px;
  }
  .logo img {
    margin-left: 0;
  }
  section#metrics {
    padding: 80px 60px 0px;
  }

  section#blogs {
    padding: 40px 0;
  }
  .heading h3 {
    font-size: 35px;
  }

  .supporting_text {
    font-size: 10px;
  }

  .social_trust {
    margin: unset;
  }

  .contact-form-wrapper {
    padding: 100px 0px 0 0px;
    margin: -44px -122px 0 0px;
  }

  .top_heading {
    width: 75%;
  }

  .nav_logo img {
    margin-left: 0px;
  }

  .heading_and_supporting_text {
    margin-left: -100px;
  }
}

@media screen and (max-width: 768px) {
  .top_heading span {
    font-size: 14px;
  }
  .top_heading {
    width: 100%;
  }
  .contact-form .title {
    font-size: 18px;
  }

  .contact-form .description {
    font-size: 11px;
  }

  .contact-form .form-input,
  .form-text-area {
    height: 38px;
    padding-left: 8px;
  }

  .avatar img {
    width: 45px;
    height: 44px;
  }
  .form_btn {
    width: 75%;
    font-size: 15px;
  }

  .contact-form {
    padding: 20px 15px;
  }

  .frm_bottom {
    font-size: 11px;
  }

  .header img {
    height: 58px;
    margin-bottom: 60px;
  }

  .testi_heading h2 {
    font-size: 45px;
  }

  .testi_text p {
    font-size: 24px;
  }
  span.u_description {
    font-size: 12px;
  }

  span.u_name {
    font-size: 16px;
  }

  .card {
    padding: 31px 15px;
    height: 350px;
    margin: 5px;
  }
  .col-sm-4 {
    width: 100%;
  }

  .Benefits_heading h3 {
    font-size: 36px;
  }
  .supporting p {
    font-size: 12px;
  }

  .bdr {
    border: none;
  }

  p.matrics_para span {
    font-size: 50px;
  }

  p.matrics_para {
    font-size: 18px;
  }
  .heading_metrics h5 {
    font-size: 35px;
  }
  .blog_content {
    padding: 0 0px;
  }
  .faq_heading h2 {
    font-size: 40px;
  }

  section#CTA {
    padding: 18px 64px;
  }

  .CTA_btn {
    border-radius: 25px;
    font-size: 16px;
  }

  .CTA_image {
    overflow: hidden;
    width: 940px;
    border-radius: 10px;
    margin-top: -145px;
    /* height: 540px; */
  }
  .row.CTA_cntn {
    flex-direction: column;
  }

  .footer_button .btn {
    font-size: 14px;
    margin-top: 18px;
  }

  .faq_supporting p {
    font-size: 16px;
  }

  .footer_content p {
    font-size: 10px;
    margin-top: 20px;
  }
  .CTA_image img {
    height: 340px;
    width: 600px;
  }

  .accordion button[aria-expanded="true"] + .accordion-content {
    opacity: 1;
    max-height: 20em;
    transition: all 200ms linear;
    will-change: opacity, max-height;
  }
  .stars img {
    height: 40px;
  }
}
@media screen and (max-width: 578px) {
  section#banner {
    padding: 110px 0 40px;
    background-color: #f8f8f8;
  }
  .top_heading {
    width: 75%;
  }

  .heading_and_supporting_text {
    margin-left: 0px;
  }
  .social_trust {
    margin: auto;
  }
  .Benefits_heading h3 {
    font-size: 30px;
  }
  .avatar-group {
    margin-left: 0px;
  }
  .benefits_bottom {
    padding-right: 0;
  }
  .testi_heading h2 {
    font-size: 40px;
  }
  .blog_content {
    margin-bottom: 0 !important;
  }
  section#faq {
    padding: 20px 40px 0;
  }
  .faq_content {
    width: 100%;
  }
  .accordion button .accordion-title {
    font-size: 15px;
  }
  .left_heading h2 {
    font-size: 32px;
  }

  .left_content {
    padding: 70px 15px;
    gap: 90px;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
  }
  .nav_logo img {
    height: 121px;
    margin-left: 0px;
    position: absolute;
    top: -26px;
  }
  .footer_content p {
    font-size: 10px;
    margin-top: 5px;
  }
  .col-lg-4.blogs {
    flex-direction: column;
    text-align: center;
    padding: 20px 0 0px 0px;
  }
  .blog_heading h5 {
    font-size: 38px;
  }
  .testimonial-cards {
    display: table-row-group;
  }
  section#testimonial {
    padding: 40px 60px;
  }
  .contact-form-wrapper {
    padding: 50px 0px 0 0px;
    margin: 0px 60px 0 60px;
  }
}
/* poco */
@media screen and (max-width: 438px) {
  .top_heading {
    width: 89%;
    font-size: 10px;
  }
  .testimonial-cards {
    display: flex;
  }
  .social_trust {
    width: 70%;
  }
  .contact-form-wrapper {
    margin: 0px 0px 0 0px;
  }
  .testi_heading h2 {
    font-size: 31px;
  }
  .heading_metrics h5 {
    font-size: 30px;
  }
  section#metrics {
    padding: 80px 0px 0px;
  }
  section#testimonial {
    padding: 40px 0px;
  }
  .faq_heading h2 {
    font-size: 32px;
  }
  .accordion .accordion-item {
    padding: 0 10px 0 18px;
  }
  .accordion .accordion-content p {
    font-size: 0.7rem;
  }
  .accordion button .accordion-title {
    font-size: 11px;
  }
  .faq_supporting p {
    font-size: 14px;
  }
  .accordion button {
    padding: 0.5em 0;
  }
  .blog_heading h5 {
    font-size: 30px;
  }
}
/* Iphone SE */
@media screen and (max-width: 375px) {
  .top_heading {
    font-size: 10px;
    width: 100%;
  }
  .top_heading span {
    font-size: 12px;
  }
  .heading h3 {
    width: 100%;
  }
  .supporting_text {
    width: 100%;
  }
  .blog_heading h5 {
    font-size: 25px;
  }
  .accordion button .accordion-title {
    font-size: 13px;
  }
  .accordion .accordion-item {
    padding: 0px 10px 0 17px;
  }
  .accordion .accordion-content p {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0.5em 0;
  }
  .accordion {
    margin: 0 -19px 0 -19px;
  }
  .nav_logo img {
    height: 100px;
    margin-left: 0px;
    position: absolute;
    top: -15px;
  }
  .faq_heading h2 {
    font-size: 28px;
  }
  .social_trust {
    flex-direction: column;
  }
  section#CTA {
    padding: 18px 26px;
  }
  .testimonial-cards {
    display: flex;
  }
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    text-align: center;
    z-index: 3333;
  }
}
